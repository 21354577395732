@font-face {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Neue Montreal'),
        url('./assets/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMontreal-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Neue Montreal';
    font-weight: 500;
    font-display: fallback;
    src: local('Neue Montreal'),
        url('./assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMontreal-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Neue Montreal';
    font-style: italic;
    font-display: fallback;
    src: local('Neue Montreal'),
        url('./assets/fonts/PPNeueMontreal-Italic.woff2') format('woff2'),
        url('./assets/fonts/PPNeueMontreal-Italic.woff') format('woff');
}


/*Beamer Snippet  everything is important since beamer loads their styles after all our styles are loaded.*/
.beamerAnnouncementSnippet {
    border: 1px solid #f6f6f6 !important;
    border-radius: 8px !important;
    box-shadow: 0 8px 8px 0px rgb(0 0 0 / 7%) !important;
    max-height: none !important;
    max-width: 400px !important;
}
.beamerAnnouncementSnippet:hover {
    box-shadow: 0 4px 4px 0px rgb(0 0 0 / 10%) !important;
}
.beamerAnnouncementSnippetContent {
    max-height: none !important;
    padding: 16px !important;
}
.beamerAnnouncementSnippetTitle {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}
.beamerAnnouncementSnippetText {
    color: #717171 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 8px 0 !important;
}
.beamerAnnouncementSnippetLink {
    color: #2e6cb5 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}
.beamerAnnouncementSnippetClose {
    color: rgba(0, 0, 0, 1) !important;
    font-family: 'Helvetica Neue', sans-serif !important;
    font-size: 32px !important;
    right: 10px !important;
    top: 0px !important;
}
.beamerAnnouncementSnippetThumb {
    max-width: 120px !important;
    padding: 20px !important;
}
.beamerAnnouncementSnippetThumb img {
    border-radius: 8px 0 0 8px !important;
    min-height: 0 !important;
    object-fit: contain !important;
}

/*Beamer Banner*/
.beamerAnnouncementBarTag {
    display: none !important;
}
.beamerAnnouncementBarButton {
    background: #000000 !important;
    border-radius: 0 !important;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: auto !important;
    line-height: 16px !important;
    padding: 8px !important;
    text-transform: none !important;
}
.beamerAnnouncementBarText {
    font-size: 14px !important;
    font-weight: 500 !important;
    max-width: 90% !important;
}
